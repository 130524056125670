<template>
  <div class="legal-notice">
    <h1>Legal Notice</h1>
    <section>
      <h2>Publisher</h2>
      <p>The website <strong>webeasy.pro</strong> is operated and published by:</p>
      <p><strong>WEBEASY LLC</strong></p>
      <p>Limited Liability Company registered in the State of New Mexico, USA</p>
      <p>EIN (Employer Identification Number): 33-4137679</p>
      <p><strong>Registered Address:</strong></p>
      <p>1209 Mountain Road Pl NE, Suite N<br>Albuquerque, NM 87110, United States</p>
      <p>Email: <a href="mailto:hello@webeasy.pro">hello@webeasy.pro</a></p>
    </section>
    <section>
      <h2>Hosting</h2>
      <p>The website is hosted by:</p>
      <p><strong>OVH SAS</strong></p>
      <p>2 rue Kellermann, 59100 Roubaix, France</p>
      <p>Website: <a href="https://www.ovhcloud.com" target="_blank" rel="noopener">https://www.ovhcloud.com</a></p>
      <p>Phone: +33 9 72 10 10 07</p>
    </section>
    <section>
      <h2>Liability</h2>
      <p>WEBEASY LLC makes every effort to provide accurate and up-to-date information on its website. However, the company cannot be held responsible for any errors, omissions, or delays in updating the content.</p>
      <p>Access to the site may be interrupted at any time without prior notice for maintenance or technical reasons.</p>
    </section>
    <section>
      <h2>Intellectual Property</h2>
      <p>All content on this website (texts, images, graphics, logos, etc.) is the exclusive property of WEBEASY LLC unless stated otherwise. Any unauthorized reproduction or use of any content is strictly prohibited and may result in legal action.</p>
    </section>
    <section>
      <h2>Personal Data</h2>
      <p>WEBEASY LLC is committed to protecting the privacy of users' personal data, in compliance with applicable laws, including the General Data Protection Regulation (GDPR) for European users.</p>
      <p>To request access, correction, or deletion of your personal data, please contact: <a href="mailto:privacy@webeasy.pro">privacy@webeasy.pro</a></p>
    </section>
  </div>
</template>

<script>
export default {
  name: 'LegalNoticePage',
};
</script>

<style scoped>
.legal-notice {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
  line-height: 1.6;
}
a {
  color: #5295FF;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}
</style>
