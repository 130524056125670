<template>
  <div>
    <NavBar class="max-width" />
    <router-view v-slot="{ Component }">
      <Transition name="page-transition" mode="out-in">
        <component :is="Component" />
      </Transition>
    </router-view>
    <div class="bottom-content"> 
      <p class="left">
        <router-link to="/">© webeasy 2025</router-link>
      </p>
      <p class="right">
        <router-link to="/legal-notice">legal notice</router-link>
      </p>
    </div>
    <button class="theme-toggle" @click="toggleTheme">
      <span v-if="theme === 'light-theme'">🌙</span>
      <span v-else>☀️</span>
    </button>
  </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue';
import '/styles/global.css';

export default {
  name: 'App',
  components: {
    NavBar,
  },
  data() {
    return {
      theme: 'light-theme',
    };
  },
  watch: {
    theme(newTheme) {
      document.documentElement.className = newTheme;
    },
  },
  methods: {
    toggleTheme() {
      this.theme = this.theme === 'light-theme' ? 'dark-theme' : 'light-theme';
    },
  },
  mounted() {
    document.documentElement.className = this.theme;
  },
};
</script>

<style scoped>

.bottom-content {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 0px;
}
.bottom-content p {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-weight: bold;
}

.bottom-content .left {
  text-align: left;
}

.bottom-content .right {
  text-align: right;
}
.max-width {
  margin-left: 4.7vw;
  margin-right: 4.7vw;
}

/* Style pour le bouton de changement de thème */
.theme-toggle {
  position: fixed;
  bottom: 20px;
  left: 20px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  transition: transform 0.3s;
}

.theme-toggle:hover {
  transform: scale(1.2);
}

/* Transition élégante pour les changements de page */
.page-transition-enter-active, .page-transition-leave-active {
  transition: opacity 0.6s ease, transform 0.6s ease;
}
.page-transition-enter-from {
  opacity: 0;
  transform: translateY(20px); /* Légère translation vers le bas */
}
.page-transition-leave-to {
  opacity: 0;
  transform: translateY(-20px); /* Légère translation vers le haut */
}
</style>