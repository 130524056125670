<template>
  <div id="scene" class="container">
    <div class="particle a" v-parallax="0.1">
    </div>
    <div class="particle e2" v-parallax="0.4">
    </div>
    <div class="particle b" v-parallax="0.15">
    </div>
    <div class="particle c" v-parallax="0.3">
    </div>
    <div class="particle d" v-parallax="0.1">
    </div>
    <div class="particle c2" v-parallax="0.3">
    </div>
    <div class="particle e" v-parallax="0.4">
    </div>
  </div>
</template>

<script>
/* import Parallax from 'parallax-js'; salut ca va al hamdoulilah et toi*/

export default {
  name: 'ParticlesComponent',
  props: {
  },
  mounted() {
/*     var scene = document.getElementById('scene');
new Parallax(scene); */
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* @keyframes floating {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
} */

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px); /* Particules commencent plus bas */
  }
  100% {
    opacity: 0.8;
    transform: translateY(0); /* Particules arrivent à leur position finale */
  }
}

.container {
  width: 100vw;
  height: 120vh;
}

.particle {
  border-radius: 100%;
  backdrop-filter: blur(5px);
  filter: blur(2px);
  animation: fadeIn 2s ease-in-out; /* Séparation des animations */
  z-index: 1;
}

.a {
  background: linear-gradient(90deg, #0E7CB3 0%, #86f7fd0e 100%);
  position: absolute;
  left: 5%;
  top: 25%;
    width: 120px;
  height: 120px;
    z-index: 2;
  animation-duration: 2.5s; /* Affecte uniquement fadeIn */
      opacity: 0.8;
}

.b {
  background: linear-gradient(90deg, #0e7cb33d 0%, #86F7FD 100%);
  position: absolute;
  right: 2%;
  top: 45%;
    width: 80px;
  height: 80px;
    z-index: 2;
  animation-duration: 2s;
      opacity: 0.8;
}

.c {
  background: linear-gradient(90deg, #1CECD4 0%, #083C64 100%);
  position: absolute;
  left: 15%;
  top: 55%;
    width: 50px;
  height: 50px;
  animation-duration: 1.8s;
      opacity: 0.8;
}

.c2 {
  background: linear-gradient(90deg, #1CECD4 0%, #083C64 100%);
  position: absolute;
  left: 80%;
  top: 60%;
    width: 50px;
  height: 50px;
  animation-duration: 1.8s;
      opacity: 0.8;
}

.d {
  background: linear-gradient(90deg, #0e7cb328 0%, #1184CD 100%);
  position: absolute;
  left: 55%;
  top: 65%;
    width: 100px;
  height: 100px;
  z-index: 2;
  animation-duration: 2.2s;
      opacity: 0.8;
}

.e {
  background: linear-gradient(90deg, #0e7cb32c 0%, #86F7FD 100%);
  position: absolute;
  left: 10%;
  top: 95%;
    width: 34px;
  height: 34px;
  animation-duration: 1.5s;
      opacity: 0.8;
}

.e2 {
  background: linear-gradient(90deg, #0e7cb32c 0%, #86F7FD 100%);
  position: absolute;
  left: 90%;
  top: 30%;
    width: 34px;
  height: 34px;
  animation-duration: 1.5s;
      opacity: 0.8;
}
</style>
