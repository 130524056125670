<template>
    <div class="particles-container">
      <div
        v-for="n in numParticles"
        :key="n"
        class="particle"
        :style="generateParticleStyle()"
      ></div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'DesParticles',
    data() {
      return {
        numParticles: 30, // Nombre de particules
      };
    },
    methods: {
      generateParticleStyle() {
        const size = Math.random() * 10 + 2 + 'px';
        const left = Math.random() * 100 + '%';
        const duration = Math.random() * 1 + 10 + 's';
        const delay = Math.random() * 10 + 's';
        const opacity = Math.random() * 0.8 + 0.2;
        const randomColor = Math.floor(Math.random() * 2) + 1 == 1 ? '#1ce9d5' : '#2b92f8c5';
        const randomBlur = `blur(` + Math.floor(Math.random() * 1) + 2 + `px)`;

        return {
          width: size,
          height: size,
          left,
          animationDuration: duration,
          animationDelay: delay,
          opacity,
          backgroundColor: randomColor,
          filter: randomBlur
        };
      },
    },
  };
  </script>
  
  <style scoped>
  .particles-container {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  
  .particle {
    position: absolute;
    bottom: -20px;
    border-radius: 50%;
    animation: rise 4s ease-out infinite;/* , drift 8s ease-in-out infinite  #2b92f8c5*/
    z-index: 1;
    box-shadow: 0 0 15px rgba(30, 144, 255, 0.5), 
              0 0 30px rgba(30, 144, 255, 0.4), 
              0 0 45px rgba(30, 144, 255, 0.3), 
              0 0 60px rgba(30, 144, 255, 0.2);
  }
  
  @keyframes rise {
    to {
      transform: translateY(-170vh);
    }
  }
  
  @keyframes drift {
    0%, 100% {
      transform: translateX(0);
    }
    50% {
      transform: translateX(20px);
    }
  }
  </style>
  