<template>
  <div class="work-cards-container">
    <!-- Boucle sur les colonnes -->
    <div class="column" v-for="(column, columnIndex) in columns" :key="columnIndex">
      <!-- Boucle sur les cartes dans chaque colonne -->
      <div class="card" v-for="(project, projectIndex) in column" :key="projectIndex" @click="goToWorkDetail(project.slug)">
        <img
          class="card-image scroll-hidden"
          :src="require(`@/assets/img_page_work/${project.image}`)"
          :alt="project.companyName"
          v-scroll="'scroll-hidden'" 
        />
        <div class="tags">
          <span class="tag" v-for="(type, index) in project.projectType" :key="index">{{ type }}</span>
        </div>
        <h3 class="card-title">{{ project.companyName }}</h3>
        <p class="card-description">{{ project.companyDescription }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WorkCardsPageWorks',
  props: {
    projects: {
      type: Array,
      required: true,
    },
  },
  computed: {
    // Divise les projets en deux colonnes
    columns() {
      const mid = Math.ceil(this.projects.length / 2);
      return [this.projects.slice(0, mid), this.projects.slice(mid)];
    }
  },
  methods: {
    goToWorkDetail(slug) {
      this.$router.push(`/works/${slug}`);
    }
  }
};
</script>

<style scoped>
/* Conteneur principal */
.work-cards-container {
  display: flex;
  flex-wrap: wrap; /* Permet de passer à une colonne si nécessaire */
  gap: 20px;
  justify-content: center; /* Centre les colonnes */
}

/* Colonnes */
.column {
  display: flex;
  flex-direction: column;
  gap: 5vh;
  flex: 1; /* Les colonnes prennent une largeur égale */
  max-width: 50%; /* Limite la largeur à 50% */
}

/* Colonne 1 : aligner les éléments à la fin */
.column:first-child {
  justify-content: start;
  align-items: flex-end; /* Centre horizontalement à droite */
}

/* Colonne 2 : aligner les éléments au début */
.column:last-child {
  align-items: flex-start; /* Centre horizontalement à gauche */
}

/* Carte individuelle */
.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  cursor: pointer;
  max-width: 570px;
  transition: box-shadow 0.3s ease; /* Transition uniquement pour l'ombre */
}

/* Image */
.card-image {
  width: 100%; /* Largeur fixe */
  height: auto; /* Hauteur automatique pour respecter le ratio */
  border-radius: 30px;
  transition: transform 0.3s ease, filter 0.3s ease; /* Transition pour l'image */
}

/* Effet de hover sur l'image */
.card:hover .card-image {
  transform: rotate(1deg); /* Légère rotation */
  filter: brightness(1.1); /* Augmente légèrement la luminosité */
}

/* Titre */
.card-title {
  font-size: 36px;
  font-weight: bold;
  color: #333;
  width: 100%; /* Prend toute la largeur de la carte */
  text-align: start; /* Centre le texte */
  text-transform: uppercase; /* Met le texte en majuscules */
  margin-bottom: 0px;
}

/* Description */
.card-description {
  font-size: 1rem;
  font-weight: lighter;
  color: #666;
  width: 100%; /* Prend toute la largeur de la carte */
  text-align: start;
  text-wrap: wrap; /* Permet le retour à la ligne */
}

.tags {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-top: 10px;
  width: 100%;
}

.tag {
  background-color: #f0f0f0;
  color: #333;
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 0.9rem;
}

/* Responsive : une colonne pour les petits écrans */
@media only screen and (max-width: 1280px) {
  .work-cards-container {
    flex-direction: column;
    align-items: center;
  }

  .column {
    max-width: 100%; /* Les colonnes prennent toute la largeur */
    justify-content: center; /* Réinitialise l'alignement pour les petits écrans */
    align-items: center;
  }
}
</style>