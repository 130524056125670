<template>
  <div class="not-found">

    <h1 class="big-h1 gradient-text">404</h1>
    <p>Oops! La page que vous cherchez n'existe pas.</p>
    <router-link to="/">
      <ButtonComponent msg="Retour à l'accueil" />
    </router-link>
    <particules class="particules" />
  </div>
</template>

<script>
import ButtonComponent from '@/components/ButtonComponent.vue';
import particules from '@/components/particules.vue';

export default {
  name: 'NotFoundPage',
  components: {
    ButtonComponent,
    particules
  },
};
</script>

<style scoped>
.not-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  gap: 20px;
}

.not-found p {
  font-size: 18px;
  color: var(--text-color);
}
.particules {
  position: absolute;
  pointer-events: none;
  height: 100vh;
}
</style>
