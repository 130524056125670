export default {
    mounted(el, binding) {
      const options = {
        threshold: 0.1, // Déclenche l'animation lorsque 10% de l'élément est visible
      };
  
      const callback = (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // Supprime la classe cachée et ajoute la classe d'animation
            el.classList.remove(binding.value || "scroll-hidden");
            el.classList.add("scroll-animate");
            observer.unobserve(el); // Arrête d'observer une fois l'animation déclenchée
          }
        });
      };
  
      const observer = new IntersectionObserver(callback, options);
      observer.observe(el);
    },
  };