<template>

    <div class="background blur">
      <div class="text-container">
        <h1 class="big-h1 gradient-text">Let's talk about your project</h1>
        <p>The full cycle of services to create and launch a website from scratch or redesign a live website along with the modern trends and requirements.</p>
        <ButtonComponent msg="Let's Talk" href="mailto:hello@webeasy.pro"/>
        <div class="social-media-container">
<!--           <div class="social-media"><img src="@/assets/instaIcon.webp" alt="" class="icon"></div>
          <div class="social-media"><img src="@/assets/tiktokIcon.webp" alt="" class="icon"></div> -->
          <div class="social-media" @click="openLinkedIn"><img src="@/assets/linkedinIcon.webp" alt="" class="icon"></div>
<!--           <div class="social-media"><img src="@/assets/facebookIcon.webp" alt="" class="icon"></div> -->

        </div>
       
      </div>

    </div>

</template>

<script>
import ButtonComponent from './ButtonComponent.vue';

export default {
  name: 'FooterComponent',
  components: {
        ButtonComponent
    },
  props: {
    msg: String
  },
  methods: {
    openLinkedIn() {
      window.open('https://www.linkedin.com/company/webeasy-agence/?originalSubdomain=ma', '_blank');
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.social-media-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
  margin-top: 40px;
  flex-wrap: wrap;
}
.social-media {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  border: 1px solid #5294ff1e;
  background-color: #5294ff10;
  border-radius: 10px;
  box-shadow: inset 0px 0px 14px 0px rgba(82, 148, 255, 0.049);
  backdrop-filter: blur(10px);
}
.social-media:hover {
  background-color: #5294ff20;
  box-shadow: 0px 0px 10px 2px rgba(82, 148, 255, 0.3);
  transform: scale(1.1);
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}
/* Suppression de l'effet hover sur les images */
.social-media img {
  transition: transform 0.3s ease-in-out;
}
.background {
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('@/assets/background.webp');
  background-size: cover;
  background-position: center;
  border-radius: 30px;
  padding: 20px;
  margin: 20px;
}


.text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
z-index: 1;
  text-align: center;
}

.text-container p {
  width: 70%;

}

.text-container a {
  color: white;
  text-decoration: none;
  font-size: 1rem;
}

.text-container a:hover {
  color: #c6c6c6;
  text-decoration: underline;
  transition: color 0.3s ease-in-out, text-decoration 0.3s ease-in-out;
}

</style>
