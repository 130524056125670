<template>

    <div class="background blur">
      <div v-if="!work" class="text-container">
        <h1 v-if="title" class="gradient-text medium-h1">{{title}}</h1>
        <p v-if="text">{{text}}</p>
        <ButtonComponent v-if="buttonText" :msg='buttonText' :href='href'/>
      </div>
      <div v-if="work" class="text-container">
        <h1 class="gradient-text medium-h1">{{project.companyName}}</h1>
        <p>{{project.companyDescription}}</p>
        <ButtonComponent v-if="buttonText" :msg='buttonText' :href='href'/>
      </div>
    </div>

</template>

<script>
import ButtonComponent from './ButtonComponent.vue';
import projects from '@/data/projects.json'; // Assurez-vous que le chemin est correct

export default {
  name: 'HeroSection',
  data() {
    return {
      project: null
    };
  },
  created() {
    const projectSlug = this.$route.params.slug; // Assurez-vous que le paramètre correspond à la route
    this.project = projects.find(project => project.slug === projectSlug);
  },
  components: {
        ButtonComponent
    },
  props: {
    title: String,
    text: String,
    buttonText: String,
    href: String,
    work: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.background {
/*   height: calc(100vh - 140px); */
height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('@/assets/background.webp');
  background-size: cover;
  background-position: center;
  border-radius: 30px;
  padding: 20px;
  padding-top: 50px;
  padding-bottom: 50px;
  margin-bottom: 5vh;
}

.text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
z-index: 1;
  text-align: center;
}

.text-container p {
  width: 70%;

}

</style>
