<template>
  <div class="services-section">
    <ServicesTitle class="servicetitle scroll-hidden" v-scroll="'scroll-hidden'" />
    <test
      title="Branding and Identity" 
      text="We craft unique brand identities that speak your values and connect with your audience — from logos and brand guidelines to complete visual systems."
      logo="illu1.webp"
      class="scroll-hidden"
      v-scroll="'scroll-hidden'" 
    />
    <test 
      title="Website Design and development" 
      text="We design and develop sleek, responsive websites tailored to your goals — combining elegant UI/UX with cutting-edge performance and SEO best practices."
      logo="illu2.webp" 
      :reverse="true"
      class="scroll-hidden"
      v-scroll="'scroll-hidden'" 
    />
    <test 
      title="Mobile App development" 
      text="We build fast, intuitive, and scalable mobile apps — from MVPs to full-featured applications — with a strong focus on user experience and efficiency."
      logo="illu4.webp"
      class="scroll-hidden"
      v-scroll="'scroll-hidden'" 
    />
    <test 
      title="E-commerce" 
      text="From product pages to checkout, we create seamless e-commerce platforms that drive conversions — with custom features, secure payments, and easy management."
      logo="illu3.webp" 
      :reverse="true"
      class="scroll-hidden"
      v-scroll="'scroll-hidden'" 
    />
  </div>
</template>

<script>
import ServicesTitle from '@/components/ServicesTitle.vue'
import test from '@/components/test.vue'

export default {
  name: 'ServicesSection',
  components: {
    ServicesTitle,
    test
  }
}
</script>

<style scoped>
.services-section {
  display: flex;
  flex-direction: column;
  margin-top: 10vh;
  margin-bottom: 2vh;
}
.servicetitle {
  margin-bottom: 5vh;
}
</style>
