<template>
  <div class="main-container">
    <FeedbackTitle class="margin-bottom20"/>
<div class="carousel margin-bottom20">
  <div class="carousel-item">
    <div class="top">
    <div class="img-logo-container">
      <img src="@/assets/logo_colicoli.webp" alt="" class="logo">
    </div>

      <div class="text">
        <p class="company">Colicoli</p>
        <p class="date">01/10/2022</p>
      </div>
    </div>
    <div class="bot"><p class="avis">Nous avons pu bénéficier d'une expertise qui a permis au site colicoli de répondre à nos besoins. La pertinence du choix graphique et la construction du site permet aux utilisateurs de profiter d'une ergonomie intuitive essentielle pour la navigation de nos clients. Merci à webeasy pour l'implication du projet colicoli. Mounir</p></div>
  </div>
  <div class="carousel-item">
    <div class="top">
      <div class="img-logo-container">
      <img src="@/assets/logoSAP.webp" alt="" class="logo">
      </div>
      <div class="text">
        <p class="company">SAP</p>
        <p class="date">01/10/2022</p>
      </div>
    </div>
    <div class="bot"><p class="avis">Nous avons pu bénéficier d'une expertise qui a permis au site colicoli de répondre à nos besoins.</p></div>
  </div>
  <div class="carousel-item">
    <div class="top">
      <div class="img-logo-container">
      <img src="@/assets/logo_meyou.webp" alt="" class="logo">
      </div>
      <div class="text">
        <p class="company">Colicoli</p>
        <p class="date">01/10/2022</p>
      </div>
    </div>
    <div class="bot"><p class="avis">Nous avons pu bénéficier d'une expertise qui a permis au site colicoli de répondre à nos besoins. La pertinence du choix graphique et la construction du site permet aux utilisateurs</p></div>
  </div>
  <div class="carousel-item">
    <div class="top">
      <div class="img-logo-container">
      <img src="@/assets/logo_colicoli.webp" alt="" class="logo">
      </div>
      <div class="text">
        <p class="company">Colicoli</p>
        <p class="date">01/10/2022</p>
      </div>
    </div>
    <div class="bot"><p class="avis">Nous avons pu bénéficier d'une expertise qui a permis au site colicoli de répondre à nos besoins. La pertinence du choix graphique et la construction du site permet aux utilisateurs de profiter d'une ergonomie intuitive essentielle pour la navigation de nos clients. Merci à webeasy pour l'implication du projet colicoli. Mounir</p></div>
  </div>
  <div class="carousel-item">
    <div class="top">
      <div class="img-logo-container">
      <img src="@/assets/logo_colicoli.webp" alt="" class="logo">
      </div>
      <div class="text">
        <p class="company">Colicoli</p>
        <p class="date">01/10/2022</p>
      </div>
    </div>
    <div class="bot"><p class="avis">Nous avons pu bénéficier d'une expertise qui a permis au site colicoli de répondre à nos besoins. La pertinence du choix graphique et la construction du site permet aux utilisateurs de profiter d'une ergonomie intuitive essentielle pour la navigation de nos clients. Merci à webeasy pour l'implication du projet colicoli. Mounir</p></div>
  </div>
  <div class="carousel-item">
    <div class="top">
      <div class="img-logo-container">
      <img src="@/assets/logo_colicoli.webp" alt="" class="logo">
      </div>
      <div class="text">
        <p class="company">Colicoli</p>
        <p class="date">01/10/2022</p>
      </div>
    </div>
    <div class="bot"><p class="avis">Nous avons pu bénéficier d'une expertise qui a permis au site colicoli de répondre à nos besoins. La pertinence du choix graphique et la construction du site permet aux utilisateurs de profiter d'une ergonomie intuitive essentielle pour la navigation de nos clients. Merci à webeasy pour l'implication du projet colicoli. Mounir</p></div>
  </div>
</div>
  </div>

</template>

<script>
  import FeedbackTitle from '@/components/FeedbackTitle.vue'
export default {
  name: 'CarousselComponent',
  components: {
    FeedbackTitle
  },
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.main-container {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  width: 100%;
}
.top {
  display: flex;
  width: 100%;
}
.img-logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  margin: 10px;
}
.logo {
  height: 100%;
  width: auto;
}
.text {
  line-height: 0.1;
}
.carousel {
  display: flex;
  gap: 10px;
  width: 100%;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
}

.carousel-item {
  flex: 1 0 400px;
  height: fit-content;
  padding: 20px;
  border-radius: 30px;
  border: 1px solid #5294ff3d;
  box-shadow: inset 0px 0px 14px 0px rgba(82, 148, 255, 0.106);
/*   background: rgb(46,126,254); */
background: linear-gradient(130deg, rgba(46,126,254,0.2) -90%, rgba(151,86,86,0) 50%, rgba(46,126,254,0.2) 190%);
  color: white;
  scroll-snap-align: start;
  scroll-margin: 5px;
}

.title {
  max-width: 170px;
  margin: 0 0 25px;
  font-weight: 900;
}

.stars {
  color: gold;
}

.enroll {
  color: #4ba4e4;
}

.enroll:hover {
  text-decoration: none;
  color: #2d88c9;
}

.margin-bottom20 {
  margin-bottom: 20px;
}

</style>
