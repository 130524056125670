<template>
    <nav class="nav">
        <img src="@/assets/logoWebeasyDark.svg" @click="goToHome" />
        <div class="nav-items" :class="{ open: isMenuOpen }">
            <ul>
                <li><a :class="{ current: isCurrentNav('/') }" href="/">Home</a></li>
                <li><a :class="{ current: isCurrentNav('/works') }" href="/works">Works</a></li>
<!--                 <li><a :class="{ current: isCurrentNav('/services') }" href="#">Services</a></li>
                <li><a :class="{ current: isCurrentNav('/pricing') }" href="#">Pricing</a></li> -->
            </ul>
            <ButtonComponent msg="Let's Talk" href="mailto:hello@webeasy.pro" />
        </div>
        <button class="menu-toggle" @click="toggleMenu">
            <span :class="{ open: isMenuOpen }"></span>
            <span :class="{ open: isMenuOpen }"></span>
            <span :class="{ open: isMenuOpen }"></span>
        </button>
    </nav>
</template>

<script>
import ButtonComponent from './ButtonComponent.vue';

export default {
    name: 'NavBar',
    components: {
        ButtonComponent
    },
    props: {
        msg: String
    },
    data() {
        return {
            isMenuOpen: false
        };
    },
    methods: {
        toggleMenu() {
            this.isMenuOpen = !this.isMenuOpen;
        },
        isCurrentNav(path) {
            return this.$route.path === path; // Utilisation de this.$route.path
        },
        goToHome() {
            this.$router.push('/');
        }
    },
    watch: {
        $route() {
            // Forcer la mise à jour de la vue lorsque la route change
            this.$forceUpdate();
        }
    }
};
</script>

<style scoped>
img {
    cursor: pointer;
}
nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 40px;
    margin-bottom: 40px;
    position: relative;
    z-index: 10; /* Ajout pour s'assurer que le menu est au-dessus */
}

ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    gap: 40px;
    margin-right: 40px;
}

nav a {
    color: white;
    text-decoration: none;
    font-size: 1rem;
    transition: color 0.3s ease;
}

nav a:hover {
    color: #c6c6c6;
}

.nav-items {
    display: flex;
    align-items: center;
    transition: transform 0.3s ease, opacity 0.3s ease; /* Ajout pour une transition fluide */
    transform: translateY(-20px);
    opacity: 0;
}

.nav-items.open {
    transform: translateY(0);
    opacity: 1;
}

.menu-toggle {
    display: none;
    flex-direction: column;
    gap: 5px;
    background: none;
    border: none;
    cursor: pointer;
}

.menu-toggle span {
    display: block;
    width: 25px;
    height: 3px;
    background-color: white;
    transition: transform 0.3s ease, opacity 0.3s ease;
}

.menu-toggle span.open:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
}

.menu-toggle span.open:nth-child(2) {
    opacity: 0;
}

.menu-toggle span.open:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
}

@media (max-width: 768px) {
    .nav-items {
        position: absolute;
        top: 100%;
        right: 0;
        background: rgba(0, 0, 0, 0.163); /* Couleur de fond semi-transparente */
        backdrop-filter: blur(10px); /* Effet de flou */
        flex-direction: column;
        align-items: flex-start;
        padding: 20px;
        gap: 20px;
        display: none;
        z-index: 20; /* Ajout pour s'assurer que le menu est au-dessus */
        border-radius: 8px; /* Ajout pour des coins arrondis */
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Ajout d'une ombre pour un effet visuel */
    }

    .nav-items.open {
        display: flex;
    }

    ul {
        flex-direction: column;
        gap: 20px;
        margin-right: 0;
    }

    .menu-toggle {
        display: flex;
    }
}

@media (min-width: 769px) {
    .nav-items {
        transform: none; /* Réinitialise la transformation */
        opacity: 1; /* Rétablit l'opacité */
    }
}
</style>
