<template>

    <div class="background">
      <div class="text-container">
        <h1 class="gradient-text big-h1 fade-in-section" style="--delay: 0s;">We bring your digital vision to life</h1>
        <p class="fade-in-section" style="--delay: 0.5s;">From your brand identity to your website, app, or custom platform — we take care of everything. Strategy, design, development — one team, one flow, zero headaches.</p>
        <ButtonComponent class="fade-in-section" style="--delay: 1s;" msg="Let's Talk" href="mailto:hello@webeasy.pro"/>
      </div>
    </div>

</template>

<script>
import ButtonComponent from './ButtonComponent.vue';

export default {
  name: 'HeroSection',
  components: {
        ButtonComponent
    },
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.background {
/*   height: calc(100vh - 140px); */
height: fit-content;
min-height: calc(100vh - 140px);
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('@/assets/background.webp');
  background-size: cover;
  background-position: center;
  border-radius: 30px;
}
.blur{

    animation: col 3s infinite alternate-reverse;
}

.blur::after{
    content: "";
/*     width: 100%;
    height: 100%; */
    background: inherit;
    background: inherit;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    filter: blur(2.1rem);
    transform: scale(1.15);
}

@keyframes col {

    0%{
        backdrop-filter: brightness(0.9);
/*         filter: brightness(1.1); */
    }
    100%{
        backdrop-filter: brightness(5);
/*         filter: brightness(1.2); */
    }
    
}

.text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  text-align: center;
}

.text-container p {
  width: 70%;

}

.fade-in-section {
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInSection 1s ease-out forwards;
  animation-delay: var(--delay);
}

@keyframes fadeInSection {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
