<template>
        <h1 class="big-h1">Feedback</h1>
</template>

<script>
export default {
  name: 'FeedbackTitle',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
