import { createRouter, createWebHistory } from 'vue-router';
import Home from '@/pages/HomePage.vue';
import Works from '@/pages/WorksPage.vue';
import WorksDetailPage from '@/pages/WorksDetailPage.vue';
import LegalNoticePage from '@/pages/LegalNoticePage.vue';
import NotFoundPage from '@/pages/NotFoundPage.vue';

const routes = [
  { path: '/', component: Home },
  { path: '/works', component: Works },
  { path: '/works/:slug', component: WorksDetailPage },
  { path: '/legal-notice', component: LegalNoticePage },
  { path: '/:pathMatch(.*)*', component: NotFoundPage }, // Route 404
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    return new Promise((resolve) => {
      setTimeout(() => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth', // Ajout du scroll fluide
        });
        resolve();
      }, 0);
    });
  },
});

export default router;
