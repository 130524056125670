import { createApp } from 'vue';
import { createHead } from '@vueuse/head';
import App from './App.vue';
import router from './router';
import parallax from './directives/parallax';
import vScroll from './directives/v-scroll';
import "../styles/global.css";

const app = createApp(App);
const head = createHead(); // Initialisation de vueuse/head

app.use(router);
app.use(head); // Utilisation de vueuse/head
app.directive('parallax', parallax);
app.directive('scroll', vScroll);
app.mount('#app');