<template>
  <div>
    <div class="top">
      <CardSection 
        title="Project Details" 
        text="Discover the details of our creative and innovative projects." 
        :work="true"
        buttonText="Back to Works" 
        href="/works"
        class="maxwidth cardSection"
      />
    </div>
    <div class="container">
      <WorkDetailContent :project="project" class="maxwidth"/>
    </div>
    <div class="bot">
      <FooterComponent class="cardSection scroll-hidden" v-scroll="'scroll-hidden'"/>
    </div>
  </div>
</template>

<script>
import { useHead } from '@vueuse/head';
import WorkDetailContent from '../components/WorkDetailContent.vue';
import CardSection from '../components/CardSectionComponent.vue';
import FooterComponent from '../components/FooterComponent.vue';
import projects from '../data/projects.json';

export default {
  name: 'WorksDetailPage',
  components: {
    WorkDetailContent,
    CardSection,
    FooterComponent
  },
  data() {
    return {
      project: null
    };
  },
  created() {
    const projectSlug = this.$route.params.slug; // Assurez-vous que le paramètre correspond à la route
    this.project = projects.find(project => project.slug === projectSlug);

    if (this.project) {
      useHead({
        title: `${this.project.companyName} X Webeasy`,
        meta: [
          { name: 'description', content: this.project.projectDescription },
          { name: 'keywords', content: `${this.project.projectType.join(', ')}, ${this.project.companyName}, Webeasy, ${this.project.companyDescription}` },
          { property: 'og:title', content: `${this.project.companyName} X Webeasy` },
          { property: 'og:description', content: this.project.projectDescription },
          { property: 'og:image', content: require(`@/assets/img_page_work/${this.project.image}`) },
          { property: 'og:type', content: 'website' }
        ]
      });
    }
  }
}
</script>

<style scoped>
.bot {
  position: relative;
  background: rgb(255,255,255);
background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 50%, rgba(255,255,255,1) 50%);
display: flex;
justify-content: center;
}
  .maxwidth {
    max-width: 1200px;
    margin-left: 4.7vw !important;
    margin-right: 4.7vw !important;
  }
.top {
  position: relative;
  background: rgb(255,255,255);
background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 50%, rgba(255,255,255,1) 50%);
display: flex;
justify-content: center;
}
.cardSection {
  width: 95vw;
  max-width: 1920px;
}
.container {
  display: flex;
  justify-content: center;
  background-color: white;
  padding: 20px;
}
</style>