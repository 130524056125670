<template>
  <div class="container">
    <div class="left">
      <div class="text-container">
        <h1 class="medium-h1 gradient-text">What can we do for you ?</h1>
        <p class="">Webeasy supports you at every digital stage — from branding and UI/UX design to website and web app development. Whether you need a logo, a full design system, or a custom platform, we’ve got you covered.</p>
        <router-link to="/works">
          <ButtonComponent msg="Take a look" />
        </router-link>
      </div>

      <div class="img-container">
        <img
          class="img1"
          :src="require('@/assets/mockup1.webp')"
          alt="imageprojet1"
        />
      </div>
    </div>
    <div class="right">
      <div class="img-container">
        <img
          class="img2"
          :src="require('@/assets/mockup2.webp')"
          alt="imageprojet2"
        />
      </div>
      <div class="img-container">
        <img
          class="img3"
          :src="require('@/assets/mockup3.webp')"
          alt="imageprojet3"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ButtonComponent from './ButtonComponent.vue';

export default {
  name: 'WorkCards',
  components: {
    ButtonComponent
  }
}
</script>

<style scoped>
.container {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 20px;
}

.left {
/*   width: 50%; */
  display: flex;
  flex-direction: column;
  align-items: end;
  gap:20px;
}

.right {
/*   width: 50%; */
  display: flex;
  flex-direction: column;
  align-items: start;
}

.img-container {
  max-width: 570px;
  filter: grayscale(100%);
}
.img-container:hover {
  filter: grayscale(0);
}
.img1 {
  width: 100%;
    border-radius: 30px;
/*   background-image: url("@/assets/mockup1.webp"); */
}
.img2 {
  width: 100%;
    border-radius: 30px;
/*   background-image: url("@/assets/mockup2.webp"); */
  margin-bottom: 20px;
}
.img3 {
  width: 100%;
    border-radius: 30px;
/*   background-image: url("@/assets/mockup3.webp"); */
}

.text-container {
  max-width: 570px;
  height: fit-content;
}

/* 
@media only screen and (max-width: 1300px) {
  .img-container {
    width: 499px;
    height: 626px;
  }
}
@media only screen and (max-width: 1100px) {
  .img-container {
    width: 430px;
    height: 539px;
  }
}
@media only screen and (max-width: 920px) {
  .img-container {
    width: 381px;
    height: 477px;
  }
} */
@media only screen and (max-width: 850px) {
  .container {
    flex-direction: column;
    align-items: center;
  }
  .left {
    align-items: center;
  }
  .right {
    align-items: center;
  }
}
</style>
