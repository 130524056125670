<template>
      <div class="text-container">
        <h1 class="medium-h1">We create <span class="gradient-text2">modern experiences</span></h1>
        <p>We design and build digital products that feel fresh, intuitive, and seamless. Whether it's a showcase website, a web or mobile app, or dynamic visual content — we turn ideas into polished, high-performing experiences.</p>
      </div>

</template>

<script>
export default {
  name: 'ServicesTitle',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
z-index: 1;
  text-align: center;
}

.text-container p {
  width: 70%;

}

</style>
