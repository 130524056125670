export default {
    mounted(el, binding) {
      const speed = binding.value || 0.5; // Définit la vitesse du parallax, 0.5 par défaut
  
      const handleScroll = () => {
        const offset = window.scrollY;
        el.style.transform = `translateY(-${offset * speed}px)`;
      };
  
      window.addEventListener('scroll', handleScroll);
  
      // Nettoyage lors de la destruction de l'élément
      el._onScroll = handleScroll;
    },
    unmounted(el) {
      window.removeEventListener('scroll', el._onScroll);
    },
  };
  